const heritageTrail = {
  type: "FeatureCollection",
  features: [
    {
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.799931888, 51.215958995],
            [-0.800031388, 51.215932095],
            [-0.800034288, 51.215935895],
            [-0.800053988, 51.215930495],
            [-0.800132288, 51.215909295],
            [-0.800157388, 51.215902595],
            [-0.800151288, 51.215893795],
            [-0.800143688, 51.215882895],
            [-0.800044188, 51.215911095],
            [-0.800028288, 51.215888495],
            [-0.799909388, 51.215918295],
            [-0.799931888, 51.215958995],
          ],
        ],
      },
      id: "06eeb789-9509-4018-b5e6-f2f836593e53",
      properties: {
        TOID: "osgb1000014489165",
        versiondate: "2022-08-26",
        versionavailablefromdate: "2022-08-27 00:00:00",
        versionavailabletodate: null,
        firstdigitalcapturedate: "1994-08-05",
        changetype: "New",
        geometry_area: "61.049075",
        geometry_evidencedate: "2016-08-23",
        geometry_updatedate: "2017-12-20",
        geometry_source: "Ordnance Survey",
        theme: "Buildings",
        description: "Building",
        description_evidencedate: "1994-08-05",
        description_updatedate: "1994-08-05",
        description_source: "Ordnance Survey",
        oslandcovertiera: "Constructed",
        oslandcovertierb: "{Building}",
        oslandcover_evidencedate: "1994-08-05",
        oslandcover_updatedate: "1994-08-05",
        oslandcover_source: "Ordnance Survey",
        oslandusetiera: "Residential Accommodation",
        oslandusetierb: '{"Private Residence"}',
        oslanduse_evidencedate: "1994-08-05",
        oslanduse_updatedate: "1994-08-05",
        oslanduse_source: "Ordnance Survey",
        absoluteheightroofbase: "78.88",
        relativeheightroofbase: "6.78",
        absoluteheightmaximum: "80.82",
        relativeheightmaximum: "8.72",
        absoluteheightminimum: "72.10",
        heightconfidencelevel: "Not Assessed",
        height_evidencedate: "2022-04-17",
        height_updatedate: "2022-08-24",
        height_source: "Ordnance Survey",
        associatedstructure: null,
        isobscured: false,
        physicallevel: "Surface Level",
        capturespecification: "Urban",
      },
      type: "Feature",
    },
  ],
};
export default heritageTrail;
